import mersenneTwister from 'mersenne-twister'
import qs from 'qs'

window.getQueryParam = function (name, defaultValue = null) {
  const params = qs.parse(window.location.search.slice(1))
  if (typeof params[name] !== 'undefined') {
    return params[name]
  }
  return defaultValue || null
}
const EXPERIMENT_KEY = 'PM_exp'
const isWindow = typeof window !== 'undefined'

function getFromStorage (key) {
  if (typeof window['localStorage'] !== 'undefined') {
    return window.localStorage.getItem(key)
  }
}

function saveToStorage (key, value) {
  if (typeof window['localStorage'] !== 'undefined') {
    return window.localStorage.setItem(key, value)
  }
}
function initializeExperiment () {
  let userSegment = getFromStorage(EXPERIMENT_KEY)
  if (!userSegment) {
    let twister = new mersenneTwister()
    userSegment = Math.ceil(twister.random() * 100)
    setTimeout(function () {
      saveToStorage(EXPERIMENT_KEY, userSegment)
    }, 1000)
  }
  console.log('userSegment', userSegment)
  window.userSegment = Number(userSegment)
}

function loadAndTriggerCaptcha() {
   let script = document.createElement('script');
   script.type= 'text/javascript'
   script.onload = triggerCaptcha.bind(null, 'landing')
   script.async = true
   script.src='https://www.google.com/recaptcha/api.js?render=6LeYxsIZAAAAAID0Ry3_rfq3dt0Yd1AvdU3qpl6I'
   document.getElementsByTagName('head')[0].appendChild(script)
}
function triggerCaptcha (action) {
  return new Promise((resolve, reject) => {
    if (window.grecaptcha) {
      window.grecaptcha.ready(function () {
        window.grecaptcha
          .execute('6LeYxsIZAAAAAID0Ry3_rfq3dt0Yd1AvdU3qpl6I', {
            action: action || 'navigate'
          })
          .then(function (token) {
            // console.log('token ', token)
            fetch('/.netlify/functions/verifygcp', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                token: token
              })
            })
              .then(response => {
                // console.log(response)
                return response.json()
              })
              .then(data => {
                  // for testing purpose bot can be simulated
                  // example ?pm_bot=0.6
                  let score = window.fakeBotScore || data.score ||  0.9 //fallback to default neutral score
                  let eventDetail = {
                    score: score,
                    isOk: score >= 0.7,
                    response: data
                  }
                  // our ad scripts can subscribe to event and also use window object wherever needed
                  let event = new CustomEvent('g_captcha', {
                    detail: { ...eventDetail }
                  })
                  window.g_captcha = eventDetail
                  window.dispatchEvent(event)
                  resolve(eventDetail)
              })
              .catch(err => {
                console.log('captcha err', err)
                // for now we are just assuming user is not bot if request fails
                let score = window.fakeBotScore || 0.9 // a generic value
                resolve({
                  score: score,
                  isOk: score >= 0.7,
                  response: {
                    error: err
                  }
                })
              })
          })
      })
    }
  })
}

export const onClientEntry = () => {
  if (isWindow) {
    window.triggerCaptcha = triggerCaptcha
    window.fakeBotScore = Number(window.getQueryParam('pm_bot'))
    initializeExperiment()
    /**
     * current-device executes with window dependencies, hence placing here
     * window['device'] will be created by current-device
     * without variable assignment, dev build fails
     */
    // eslint-disable-next-line
    var currentDevice = require('current-device').default
  }
}

export const onPreRouteUpdate = ({ location, prevLocation }) => {
  // prevLocation would be null on page refresh
  if(prevLocation) {
    triggerCaptcha('navigate').then(data => {
      if(window.debug_captcha) {
        console.log('promise data', data)
      }
    })
  }

}
